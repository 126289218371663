import { User } from "@/models/User";
import { UserToken } from "@/models/users/UserToken";
import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import jwt_decode from "jwt-decode";
import { getUser, refreshTokenUser } from "@/services/UserService";
import { Toast } from "@/models/Toast";
import { DataResStatus } from "@/models/DataRes";
import { Product } from "@/models/products/Product";
import { EnvironmentDto } from "@/models/environments/EnvironmentDto";
import { Breadcrumb } from "@/components/breadcrumbs/breadcrumbs";
import syncEnvironment from "./modules/sync-environment";
import versionStable from "./modules/version-stable";
import { ActionLog } from "@/models/ActionLog";
import { ListPageModel } from "@/models/ListPageModel";
import { PushRequestModel } from "@/models/PushRequestModel";
import { EnvironmentPermissions } from "@/models/environments/EnvironmentPermissions";
import { ProductPermissions } from "@/models/products/ProductPermissions";
import { FolderBreadcrumb } from "@/models/environments/FolderBreadcrumb";

type AssetsBreadcrumbSegments = { name: string; folderContentId: string }[];
export interface State {
  user: User;
  token: UserToken;
  awaitingToken: boolean;
  toasts: Toast[];
  product: Product;
  environmentRecentActionLogs: ListPageModel<ActionLog>;
  environment: EnvironmentDto;
  productEnvironments: EnvironmentDto[];
  breadcrumbs: Breadcrumb[];
  breadCrumbsX: FolderBreadcrumb;
  isLoading: boolean;
  appIsLoaded: boolean;
  isLeftSidebarPinned: boolean;
  pushRequestModel: PushRequestModel;
  environmentPermissions: EnvironmentPermissions;
  productPermissions: ProductPermissions;
  assetsCurrentPath: string;
  assetsBreadcrumbSegments: AssetsBreadcrumbSegments;
}

export const key: InjectionKey<Store<State>> = Symbol();

export enum StoreMutations {
  BreadCrumbRemove = "BreadCrumbRemove",
  BreadCrumbAdd = "BreadCrumbAdd",
}

export const store = createStore<State>({
  // plugins: [createLogger()],
  state: {
    user: new User({}),
    token: new UserToken({}),
    awaitingToken: true,
    toasts: [],
    product: new Product({}),
    environmentRecentActionLogs: new ListPageModel({}),
    environment: new EnvironmentDto({}),
    productEnvironments: [],
    breadcrumbs: [],
    breadCrumbsX: [],
    isLoading: false,
    appIsLoaded: false,
    isLeftSidebarPinned: true,
    pushRequestModel: new PushRequestModel({}),
    environmentPermissions: new EnvironmentPermissions({}),
    productPermissions: new ProductPermissions({}),
    assetsCurrentPath: "",
    assetsBreadcrumbSegments: [],
  },
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
      // This is so the bottom extended-loading-panel does not need to show after the app is loaded.. isLoading is a temp hack.
      if (isLoading === false) {
        state.appIsLoaded = true;
      }
    },
    setProductEnvironments(state, environments) {
      state.productEnvironments = environments;
    },
    userUpdate(state, user) {
      state.user = user;
    },
    tokenUpdate(state, token) {
      state.token = token;
      localStorage.setItem("authToken", JSON.stringify(token));
    },
    setAwaitingToken(state, value) {
      state.awaitingToken = value;
    },
    toastAdd(state, value) {
      state.toasts.push(value);
    },
    toastRemove(state, value) {
      const index = state.toasts.findIndex((x) => x.id === value);
      state.toasts.splice(index);
    },
    productSet(state, value) {
      state.product = value;
    },
    productRemove(state) {
      state.product = new Product({});
    },
    envSet(state, value) {
      state.environment = value;
    },
    envRemove(state) {
      state.environment = new EnvironmentDto({});
    },
    breadCrumbsXSet(state, value) {
      state.breadCrumbsX = value;
    },
    breadCrumbsXClear(state) {
      state.breadCrumbsX = [];
    },
    toggleLeftSidebar(state) {
      state.isLeftSidebarPinned = !state.isLeftSidebarPinned;
    },
    setEnvironmentRecentActionLogs(state, value) {
      state.environmentRecentActionLogs = value;
    },
    [StoreMutations.BreadCrumbAdd](state, value: Breadcrumb) {
      state.breadcrumbs.push(value);
    },
    [StoreMutations.BreadCrumbRemove](state, value: string) {
      const index = state.breadcrumbs.findIndex((x) => x.id === value);
      state.breadcrumbs.splice(index);
    },
    pushRequestSet(state, value) {
      state.pushRequestModel = value;
    },
    setEnvironmentPermissions(state, permissions) {
      state.environmentPermissions = permissions;
    },
    setProductPermissions(state, permissions) {
      state.productPermissions = permissions;
    },
    setAssetsCurrentPath(state, path: string) {
      state.assetsCurrentPath = path;
    },
    setAssetsBreadcrumbSegments(state, segments: AssetsBreadcrumbSegments) {
      state.assetsBreadcrumbSegments = segments;
    },
  },
  actions: {
    updateAssetsBreadcrumbs({ commit }, path: string) {
      commit("setAssetsCurrentPath", path);

      const segments = path
        .split("/")
        .filter(Boolean)

        .map((segment, index, array) => {
          const folderPath = array.slice(0, index + 1).join("/");

          return {
            name: `${segment}/`,
            folderContentId: folderPath ? `${folderPath}/` : "",
          };
        });

      commit("setAssetsBreadcrumbSegments", segments);
    },
    tokenAutoRefresh({ state, dispatch }) {
      const { token } = state;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { exp } = jwt_decode(token.accessToken) as any;
      const now = Date.now() / 1000; // exp is represented in seconds since epoch
      let timeUntilRefresh = exp - now - 15 * 60; // Refresh 15 minutes before it expires.
      timeUntilRefresh = timeUntilRefresh < 300 ? 300 : timeUntilRefresh; // minimum 5 mins / 300s.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const refreshTask = setTimeout(() => dispatch("tokenRefresh"), timeUntilRefresh * 1000);
      // commit('tokenRefreshTask', refreshTask) // In case you want to cancel this task on logout
    },
    async tokenRefresh({ state, commit, dispatch }) {
      try {
        const newToken = await refreshTokenUser(state.token.refreshToken.tokenString);
        commit("tokenUpdate", newToken.data);
        dispatch("tokenAutoRefresh");
      } catch (e) {
        commit("tokenUpdate", new UserToken({}));
      }
    },
    async tokenFromStorage({ commit, dispatch }) {
      try {
        const token = localStorage.getItem("authToken");
        if (token == null) {
          throw new Error("Missing token, probably never logged in.");
        }
        const tokenParsed: UserToken = JSON.parse(token);
        if (
          !tokenParsed.refreshToken ||
          !tokenParsed.refreshToken.tokenString ||
          tokenParsed.refreshToken.tokenString === ""
        ) {
          throw new Error("Invalid token stored.");
        }
        commit("tokenUpdate", tokenParsed);
        await dispatch("userLoad");
        await dispatch("tokenRefresh");
        commit("setAwaitingToken", false);
      } catch (e) {
        commit("tokenUpdate", new UserToken({}));
        commit("setAwaitingToken", false);
      }
    },
    async tokenLogout({ commit }) {
      commit("tokenUpdate", new UserToken({}));
      commit("userUpdate", new User({}));
    },
    async toastAdd({ commit, dispatch }, payload) {
      commit("toastAdd", payload.toast);
      setTimeout(() => dispatch("toastRemove", payload.toast.id), payload.toast.hideAfter);
    },
    async toastRemove({ commit }, payload) {
      commit("toastRemove", payload.toastId);
    },
    async userLoad({ commit }) {
      const user = await getUser({ noPropagate: true });

      if (user.status !== DataResStatus.Ok) {
        // Log the user out if we have trouble getting their info.
        commit("tokenUpdate", new UserToken({}));
        commit("userUpdate", new User({}));
        throw new Error("Unable to load user.");
      } else {
        commit("userUpdate", user.data);
      }
    },
  },
  modules: {
    syncEnvironment,
    versionStable,
  },
  getters: {
    toasts(state) {
      return state.toasts;
    },
    isAuthenticated(state) {
      return state.token.accessToken !== "" && state.token.accessToken != null;
    },
    isTokenInStorage() {
      return localStorage.getItem("authToken") != null;
    },
    isAwaitingToken(state) {
      return state.awaitingToken;
    },
    userIsVerified(state) {
      return state.user ? state.user.isEmailVerified : false;
    },
    user(state) {
      return state.user;
    },
    product(state) {
      return state.product;
    },
    environmentRecentActionLogs(state) {
      return state.environmentRecentActionLogs;
    },
    productContentId(state) {
      return state.product.contentId;
    },
    isProductSet(state) {
      return state.product.id && state.product.id !== 0;
    },
    environment(state) {
      return state.environment;
    },
    environmentContentId(state) {
      return state.environment.contentId;
    },
    isEnvironmentSet(state) {
      return !!(state.environment.hid && state.environment.hid !== "");
    },
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    breadCrumbsX(state) {
      return state.breadCrumbsX;
    },
    isLoading(state) {
      return state.isLoading;
    },
    isLeftSidebarPinned(state) {
      return state.isLeftSidebarPinned;
    },
    appIsLoaded(state) {
      return state.appIsLoaded;
    },
    productEnvironments(state) {
      return state.productEnvironments;
    },
    pushRequestModel(state) {
      return state.pushRequestModel;
    },
    environmentPermissions(state) {
      return state.environmentPermissions;
    },
    productPermissions(state) {
      return state.productPermissions;
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
